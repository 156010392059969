<template>
  <v-card data-cy="settings-menu">
    <!-- v-if for lazy loading of license -->
    <v-dialog v-model="showLicense" width="600px" v-if="showLicense">
      <license-card @close="showLicense = false"></license-card>
    </v-dialog>
    <v-divider></v-divider>
    <v-list subheader>
      <v-subheader>Support</v-subheader>
      <v-list-tile :href="`mailto:${supportEmail}`">
        <v-list-tile-avatar>
          <v-icon small>email</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>Email Support</v-list-tile-sub-title>
          <v-list-tile-title>{{ supportEmail }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-subheader>Feedback</v-subheader>
      <v-list-tile href="mailto:feedback@droneshield.com">
        <v-list-tile-avatar>
          <v-icon small>email</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>Email Feedback</v-list-tile-sub-title>
          <v-list-tile-title>feedback@droneshield.com</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-subheader>About</v-subheader>
      <template v-for="(item, index) in versions">
        <v-list-tile :key="`${index}-version`" :class="'version'">
          <v-list-tile-avatar>
            <v-icon v-if="index === 0" small>info</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title
            >{{ item.service }}: {{ item.version }}
            </v-list-tile-sub-title
            >
          </v-list-tile-content>
        </v-list-tile>
      </template>
      <br v-if="devOrRelease"/>
      <v-list-tile>
        <v-list-tile-avatar>
          <v-icon small>storage</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>{{ apiUrl }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile @click.stop="showLicense = true">
        <v-list-tile-avatar>
          <v-icon small>book</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-title>View License Agreement</v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-card>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
import capitalize from 'lodash/capitalize'

const LicenseCard = () => import('../License')

const props = {}

export default {
  name: 'AboutMenu',
  data: () => ({
    showLicense: false,
    isDemoMode: false,
  }),
  components: {LicenseCard},
  props,
  computed: {
    ...mapState('sites', ['activeSiteId']),
    ...mapGetters('users', ['isAuthorized', 'isAdmin']),
    ...mapGetters('sites', ['activeSite']),
    ...mapState(['systemStats']),
    ...mapState('radar_perf_stats', ['stats']),
    ...mapState('system', [
      'upgrades',
      'version',
      'apiUrl',
      'environment',
      'supportEmail'
    ]),
    ...mapState('site_warnings', ['site_warnings', 'site_warnings_history']),
    ...mapGetters('system', ['isSocketConnected']),
    devOrRelease() {
      return this.version.app === "development" || this.version.app === "release"
    },
    versions() {
      let versions = [{service: "Version", version: this.environment}]

      versions.push({service: `- API`, version: this.version})
      versions.push({service: `- UI`, version: 'd69a9453 2024-Jul-10'})

      return versions
    },
  },
  methods: {
    ...mapActions('system', {setTheme: 'SET_THEME'}),
    stopPropagation(event) {
      event.stopPropagation()
    },
    iconColor(status) {
      switch (status) {
        case 'good':
          return 'success'
        case 'warning':
          return 'primary'
        case 'error':
          return 'error'
        default:
          return 'error'
      }
    },
    closeMenu() {
      this.$bus.$emit('closeSystemSettingsMenu')
    }
  }
}
</script>

<style>
.themeSelector.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}

.themeSelector.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}

.version {
  height: 20px;
  padding-bottom: 5px;
}
</style>
